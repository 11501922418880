import * as React from "react"
import parse from 'html-react-parser'

import {Link, useStaticQuery, graphql} from 'gatsby'
import Layout from '../../components/layout/layout'
import Blogpost from "../../components/blogpost/blogpost"
import Adsense from "../../components/adsense/adsense";
const pageTitle = "VR2WOA Blog"

const IndexPage = () => {
    const query = graphql`
          {
            restApiBlogApi {
              objects {
                article_type
                content_en
                summary_en
                title_en
                slug
                mode_type
                id
                flag_online
                creation_date_timestamp
                creation_date
                thumbnail_url
              }
            }
          }`

    const objects = useStaticQuery(query)

    return (
        <Layout pageTitle={pageTitle}>
                            <Adsense client="ca-pub-6505108883414662" slot="2219379165" format="auto" />

            <div className="container-fluid">
                <div className="row ">
                    <div className="col-12 pt-4 pb-4">

                        {objects && objects.restApiBlogApi && objects.restApiBlogApi.objects ? objects.restApiBlogApi.objects.map(post => (
                                <Blogpost post={post} />
                        )) : null}


                    </div>
                </div>

            </div>

        </Layout>

    )
}

export default IndexPage
